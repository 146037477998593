/**
 * Created by Leo
 * Date: 2022-04-23 21:19
 * Email: asdfpeng@qq.com
 */
import {
	createRouter,
	createWebHashHistory
} from "vue-router";

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Index from "@/pages/index" //导入页面
import NotFound from "@/pages/NotFound";
import Login from "@/pages/Login";
import Finder from "@/pages/Finder";
import Setting from "@/pages/Setting";
import ArbitratorSelect from "@/pages/ArbitratorSelect";
// import ScanSignatureList_L1 from "@/pages/scansignature/ScanSignatureList_L1";
import ScanSignatureMenu from "@/pages/scansignature/ScanSignatureMenu";
import SignatureLogin from "@/pages/scansignature/Login";
import SignFileList from "@/pages/scansignature/FileList";
import PDFPreview from "@/pages/scansignature/PDFPreview";
import ArbitramentSignatureJump from "@/components/case/ArbitramentSignatureJump";
import DeclarationSignatureJump from "@/components/case/DeclarationSignatureJump";
import DemoPages from "@/pages/DemoPages.vue";
// 查询 [查询复函指引]页面
import SearchGuidePages from "@/components/searchGuidePages.vue";
import CaseFilePages_L1 from "@/pages/CaseFilePages_L1.vue"
import CaseFilePages_L2 from "@/pages/CaseFilePages_L2.vue"
import CaseFilePages_L3 from "@/pages/CaseFilePages_L3.vue"
import CaseFilePages_L4 from "@/pages/CaseFilePages_L4.vue"
// 老项目文件预览
import FilePreview from '@/components/filePreview.vue'
// 老项目查看对比页
import partyComparison from '@/pages/consultation/partyComparison.vue'
//外接待办消息
import Refund from "@/pages/weChat/refund.vue"
// 老项目查看消息中心
import viewMessagePages from '@/components/message/viewMessagePages.vue'
import registerCaseDemo from '@/components/registerCaseDemo.vue'

// 对外的文件预览下载的页面
import downloadFiles from '@/components/viewFile/downloadFiles.vue'

// 移动端查看操作指引
import axios from "axios";


// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。H
const routes = [{
		name: 'Login',
		path: '/login',
		component: Login, //跳转页面
	},
	// 操作指引页面 
	{
		name: 'SearchGuidePages',
		path: '/SearchGuidePages',
		component: SearchGuidePages, //跳转页面
	},
	{
		name: 'DemoPages',
		path: '/DemoPages',
		component: DemoPages, //跳转页面
	},
	{
		name: 'ArbitramentSignatureJump',
		path: '/arbitramentSignature',
		component: ArbitramentSignatureJump, //跳转页面
	},
	{
		name: 'DeclarationSignatureJump',
		path: '/declarationSignature',
		component: DeclarationSignatureJump, //跳转页面
	},
	{
		name: 'Home',
		path: '/',
		component: Index, //跳转页面
		meta: {
			auth: true,
		}
	},
	{
		name: 'Finder',
		path: '/finder',
		component: Finder,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	{
		name: 'Setting',
		path: '/setting',
		component: Setting,
		meta: {
			auth: true,
		}
	},
	{
		name: 'ArbitratorSelect',
		path: '/arbitrator',
		component: ArbitratorSelect,
		meta: {
			auth: true,
		}
	},

	{
		name: 'ScanSignatureMenu',
		path: '/scansignature',
		component: ScanSignatureMenu,
		meta: {
			auth: true,
		}
	},
	{
		name: 'SignatureLogin',
		path: '/sign_login',
		component: SignatureLogin
	},
	{
		name: 'SignFileList',
		path: '/sign_file_list',
		component: SignFileList,
		meta: {
			auth: true,
		}
	},
	{
		name: 'PreviewFile',
		path: '/sign_preview',
		component: PDFPreview,
		meta: {
			auth: true
		}
	},

	// 电子案情外面的页面打开
	{
		name: 'CaseFilePages_L1',
		path: '/CaseFilePages_L1',
		component: CaseFilePages_L1,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	// 电子案情外面的页面打开
	{
		name: 'CaseFilePages_L2',
		path: '/CaseFilePages_L2',
		component: CaseFilePages_L2,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	// 电子案情外面的页面打开
	{
		name: 'CaseFilePages_L3',
		path: '/CaseFilePages_L3',
		component: CaseFilePages_L3,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	// 电子案情外面的页面打开
	{
		name: 'CaseFilePages_L4',
		path: '/CaseFilePages_L4',
		component: CaseFilePages_L4,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	// 消息代办外面打开
	{
		name: 'Refund',
		path: '/Refund',
		component: Refund,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	// 文件预览外面的页面打开
	{
		name: 'FilePreview',
		path: '/FilePreview',
		component: FilePreview,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	// 对比页面打开
	{
		name: 'partyComparison',
		path: '/PartyComparison',
		component: partyComparison,
		meta: {
			auth: false,
			needPhone: false
		}
	},
	// 消息中心页面打开
	{
		name: 'viewMessagePages',
		path: '/ViewMessagePages',
		component: viewMessagePages,
		meta: {
			auth: false,
			needPhone: false
		}
	},

	// 添加申请人页面对外展示demo
	{
		name: 'downloadFiles',
		path: '/downloadFiles',
		component: downloadFiles,
		meta: {
			auth: false,
			needPhone: false
		}
    },
    // 对外打开直接下载的文件页面
	{
		name: 'registerCaseDemo',
		path: '/cietac',
		component: registerCaseDemo,
		meta: {
			auth: false,
			needPhone: false
		}
    },

	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: NotFound
	},
]

// 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
	// 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
	history: createWebHashHistory(),
	routes, // `routes: routes` 的缩写

})

NProgress.configure({
	easing: 'ease',
	speed: 500,
	showSpinner: false
});

//防止用户非法登录
router.beforeEach(async (to, from, next) => {
	// 处理微信扫码登录 其余逻辑在Header组件中处理
    if (to.path === "/login") {
        if (to.query.code) {
            let url = to.query.source === 'ZJ' ? 'https://www.cietacodr.org/api/zjOneStop/getUserInfo' : 'https://www.cietacodr.org/api/onestop/accessToCIETAC'
			let param = {
				req: {
					code: to.query.code
				}
			}
			let {
				data
			} = await axios({
				// target: 'http://www.cietacodr.org'
				url:url,
				method: 'POST',
				data: param
			})
			if (data.success) {
				localStorage.setItem('c_auth_token', data.data)
				next('/')
            } else {
                next('/')
            }
		}
	}

	if (to.query['authToken']) {
		localStorage.setItem('c_auth_token', to.query['authToken'])
	}
	const isAuthenticated = !!localStorage.getItem('c_auth_token');
	// 签章系统微信扫码登录失败
	if (to.path === 'sign_file_list' && !isAuthenticated) {
		next({
			name: 'SignatureLogin',
			query: {
				...to.query
			}
		})
	}

	if (['PreviewFile', 'SignFileList'].includes(to.name) && !isAuthenticated) {
		next({
			name: 'SignatureLogin'
		})
	}

	if (to.meta && to.meta.auth) {
		if (to.name !== 'Login' && !isAuthenticated) next({
			name: 'Login',
			query: {
				...to.query
			}
		})
		else next()
	} else {
		// 页面加载进度条控制
		NProgress.start();
		next()
	}

});

router.afterEach(() => {
	NProgress.done()
});

export default router;