import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'normalize.css'
import * as ElIcons from '@element-plus/icons-vue'
import router from "@/router";
import { createI18n } from 'vue-i18n'
import messages from "@/config/lang";
import NetworkHandler from "@/utils/NetworkHandler";
import axiosConfig from "@/config/api";
import '@/assets/css/common.css';
import { VueListener } from 'listener_leo';
import App from './App.vue'
import store from "@/store";
import GlobalFunction from "@/utils/GlobalFunction";
import 'tinymce/skins/ui/oxide/skin.css'

// 打印插件
import print  from 'vue3-print-nb'
// 阿里图标
import '@/assets/font/iconfont.css'
import '@/assets/font/iconfont.js'

// 复制的插件
import VueClipboard from 'vue-clipboard2'



// 角标组件
import subscript from '@/components/common/subscript.vue'


// document.write(returnCitySN["cip"]+','+returnCitySN["cname"] + "真实IP地址")

// 查看是什么浏览器内核
import getExplorerInfo from '@/utils/getExplorerInfo.js'
let info = getExplorerInfo()
console.log(info);

// 打印图标
console.log(
    '%c WEICOME TO CIETAC',
    'color: #ccc; font-size: 100px;'
)

const app = createApp(App)

app.use(print)
app.use(ElementPlus)


// 统一注册Icon图标
for (const iconName in ElIcons) {
    if (iconName === 'Menu') {
        app.component('Menu_e', ElIcons[iconName])
    } else {
        app.component(iconName, ElIcons[iconName])
    }
}

// 国际化
const i18n = createI18n({
    locale: 'zh', // set locale
    fallbackLocale: 'zh', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})


app.directive('removeAriaHidden', {
    bind(el) {
      let ariaEls = el.querySelectorAll('.el-radio__original');
      ariaEls.forEach((item) => {
        item.removeAttribute('aria-hidden');
      });
    }
  });

app.use(i18n)

// 网络处理
app.use(NetworkHandler, axiosConfig)

// 全局事件
app.use(VueListener)

// vuex
app.use(store)

app.use(GlobalFunction)

app.use(router)

app.component('subscript', subscript)

// 复制插件
app.use(VueClipboard)





app.mount('#app')


export default app;
