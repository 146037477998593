<template>
    <div class="case_wrapperManageConfigList">
        <div class="left">
            <SideMenu
                :menu_tree="menu_tree"
                @change="handleMenuChange"
                @toggle="handleToggle"
            />
        </div>
        <!-- 面包屑 -->
        <el-breadcrumb
            id="breadManageConfigList"
            separator-icon="ArrowRight"
            style="
                position: absolute;
                left: 256px;
                background-color: rgb(236, 236, 236);
                padding: 10px 5px;
                width: 100%;
            "
        >
            <el-breadcrumb-item :to="{ path: '/' }" @click="BackToCase"
                >当前位置：{{ currentPath }}</el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item>{{ $t('msg.bread_current')}}：{{ $t('msg.case_list')}}</el-breadcrumb-item> -->
            <el-breadcrumb-item v-if="caseNo">{{
                $t('msg.case') + ':' + caseNo
            }}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 系统管理外用 开始-->
        <!-- 收发查询 -->
        <div
            class="right_wrapper"
            id="NewDepartmengSet"
            v-show="right_type === 'NewDepartmengSet'"
        >
            <NewDepartmengSet ref="NewDepartmengSet"></NewDepartmengSet>
        </div>
        <!-- 收发查询 -->
        <div
            class="right_wrapper"
            id="NewUserSet"
            v-show="right_type === 'NewUserSet'"
        >
            <NewUserSet ref="NewUserSet"></NewUserSet>
        </div>

        <!-- 系统管理外用 结束-->

        <!-- 立案咨询----------------开始 -->
        <!-- 案件初审 -->
        <div
            class="right_wrapper"
            id="CaseAudition"
            v-show="right_type === 'CaseAudition'"
        >
            <CaseAudition
                ref="CaseAudition"
                @ApproveDetail="approveDetail"
            ></CaseAudition>
        </div>

        <!-- 案件审批 -->
        <div
            class="right_wrapper"
            id="CaseApproval"
            v-show="right_type === 'CaseApproval'"
        >
            <CaseApproval
                ref="CaseApproval"
                @ApproveDetail="approveDetail"
            ></CaseApproval>
        </div>

        <!-- 案件审批详情页 -->
        <div
            class="right_wrapper"
            id="CaseApprovalDetail"
            v-show="right_type === 'CaseApprovalDetail'"
        >
            <CaseApprovalDetail ref="CaseApprovalDetail"></CaseApprovalDetail>
        </div>

        <!-- 案件分配 -->
        <div
            class="right_wrapper"
            id="CaseAllocation"
            v-show="right_type === 'CaseAllocation'"
        >
            <CaseAllocation ref="CaseAllocationRef"></CaseAllocation>
        </div>

        <!-- 立案咨询----------------开始 -->

          <!-- 特殊程序指令 -->
          <div
            class="right_wrapper"
            id="SendFileToParty"
            v-show="right_type === 'SendFileToParty'"
        >
            <SendFileToParty ref="SendFileToPartyRef"></SendFileToParty>
        </div>

        <!-- 特殊程序指令------------ -->

        <div class="right_wrapper" id="empty" v-show="right_type === 'empty'">
            <el-empty description="没有数据" style="margin: 50px auto" />
        </div>
    </div>
</template>

<script>
//新增菜单，第一步在template中曾加div显示菜单。第二步在import此处导入自己新建的组件，第三步，在mothods增加方法
import SideMenu from '@/components/SideMenu'
import NewDepartmengSet from '../pages/manage/NewDepartmengSet.vue'
import CaseAudition from '../pages/register/CaseAudition.vue'
import CaseApproval from '../pages/consultation/CaseApproval.vue'
// 立案咨询模块
import CaseApprovalDetail from '../pages/consultation/CaseApprovalDetail.vue'
import NewUserSet from '../pages/manage/NewUserSet.vue'
import CaseAllocation from '../pages/consultation/CaseAllocation.vue'

// 特殊程序指令
import SendFileToParty from '../pages/SendFileToParty.vue'

//import ArbitratorSelect from "@/components/arbitrator/ArbitratorSelect";
//在export中别忘了新加的菜单项
export default {
    name: 'ManageConfigList',
    // 注册组件
    components: {
        SideMenu,
        NewDepartmengSet,
        NewUserSet,
        CaseAudition,
        CaseApproval,
        CaseApprovalDetail,
        CaseAllocation,
        SendFileToParty
    },
    created() {
        // this.GetCaseTabs()
        // 监听点击案件名称事件 设置当前case_id

        // 事件总线$listener  意识是其他地方使用this.$listener.$emit（'case_change'）就会触发这个事件
        this.$listener.$on('case_change', (row) => {
            this.right_type = 'file'
            this.$nextTick(() => {
                this.$refs.finder?.load(row.caseId)
            })
            this.case_id = row.caseId
            this.caseNo = row.caseNo
        }),
            this.$listener.$on('lang', () => {
                this.case_tabs = this.$GF.langChangeForTab(this.case_tabs)
                this.activeName = '0'
            })
    },
    props: {
        menu_tree: {
            type: Array,
            default() {
                return []
            }
        },
        activeId: {
            type: String
        }
    },
    data() {
        return {
            cur: '1',
            case_num: '',
            activeName: '我的案件',
            dialogFormVisible: false,
            bindModel: {
                bindCode: '',
                nation: '',
                register_type: ''
            },
            bindRules: {
                bindCode: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.$GF.formValidator
                    }
                ]
            },
            case_tabs: [],
            tab_loading: false,
            show_result: false,
            right_type: 'case', // case、file、submit、progress、uploadOperationLog、arbitramentSignature、declarationSignature、empty
            finderOptions: {
                lang: false
            },
            case_id: '',
            refList: [],
            caseNo: '',
            receiveDetailInfo: {},
            // 登记状态页+二维码页面
            receiveTypeData: '',
            currentPath: '系统管理',
            listMaterialListRtn: []
        }
    },
    methods: {
        load() {
            // this.GetCaseTabs()
            this.right_type = 'CaseAudition'
            this.caseNo = ''
        },
        handleMenuChange(menu_index) {
            console.log(menu_index)
            // 点击案件后的菜单
            //仲裁员菜单
            if (menu_index === 'NewDepartmengSet') {
                this.right_type = 'NewDepartmengSet'
            } else if (menu_index === 'NewUserSet') {
                this.right_type = 'NewUserSet'
            } else if (menu_index === 'CaseAudition') {
                this.right_type = 'CaseAudition'
                this.$refs.CaseAudition.load(this.case_id)
            } else if (menu_index === 'CaseApproval') {
                this.right_type = 'CaseApproval'
                this.$refs.CaseApproval.load(this.case_id)
            } else if (menu_index === 'CaseApprovalDetail') {
                this.right_type = 'CaseApprovalDetail'
                this.$refs.CaseApprovalDetail.load(this.case_id)
            } else if (menu_index === 'CaseAllocation') {
                this.right_type = 'CaseAllocation'
                this.$refs.CaseAllocationRef.load(this.case_id)
            } else if (menu_index === 'sendFileToParty') {
                this.right_type = 'SendFileToParty'
                this.$refs.SendFileToPartyRef.load(this.case_id)
            }  else {
                this.$message.warning('资源不存在')
                this.right_type = 'empty'
            }
        },

        // 根据事件跳转到指定的页面-----》重要   row:传递参数   start:要跳转的right_type
        // 根据事件跳转到指定的页面-----》重要   row:传递参数
        // 根据事件跳转到指定的页面-----》重要   row:传递参数
        goPage(row = '', start) {
            this.right_type = start
            this.$refs[start].load(row)
        },
        // 顶上面包屑点击事件
        BackToCase() {
            this.$emit('back')
            this.load()
            this.caseNo = ''
        },
        // 右侧宽度自适应
        handleToggle(val) {
            const p_width = document.querySelector(
                '.case_wrapperManageConfigList'
            ).offsetWidth
            const id = `#${this.right_type}`
            if (val) {
                document.querySelector(id).style.width = p_width - 10 + 'px'
                document.querySelector('#breadManageConfigList').style.width =
                    p_width - 10 + 'px'
                document.querySelector('#breadManageConfigList').style.left =
                    '70px'
            } else {
                document.querySelector(id).style.width = p_width - 258 + 'px'
                document.querySelector('#breadManageConfigList').style.width =
                    p_width - 258 + 'px'
                document.querySelector('#breadManageConfigList').style.left =
                    '256px'
            }
        },

        //案件审批列表编辑跳转至审批详情
        approveDetail(data) {
            this.right_type = 'CaseApprovalDetail'
            this.$refs.CaseApprovalDetail.load(data)
        }
    },
    watch: {
        case_num: function (val) {
            if (val === '') this.show_result = false
        },
        activeId: function (val) {
            if (val === '15') {
                this.right_type = 'case'
            } else if (val === '16') {
                this.right_type = 'receive'
            }
        }
    }
}
</script>

<style scoped lang="sass">
.case_wrapperManageConfigList
    display: flex
    gap: 5px
    padding: 2px
.right_wrapper
    width: 100%
    //height: calc(100vh - 145px)
    margin-top: 40px
    ::v-deep(.el-breadcrumb__inner)
        color: #7F96CD!important
    .search
        height: 80px
        box-shadow: 0 0 2px 1px #E1EAFA
        display: flex
        font-size: 13px
        align-items: center
        padding: 0 20px 0 10px
    img
        width: 24px
        height: 24px
    span
        width: 180px
    .btn_group
        width: 360px
        img
            width: 13px
            height: 13px
    ::v-deep(.el-input__wrapper)
        height: 35px!important
::v-deep(.el-tabs__item)
    color: #434A54!important
    border-radius: 3px 3px 0 0
    border-left: 1px solid #e4e7ed!important
    border-bottom: none!important
::v-deep(.el-tabs--card>.el-tabs__header .el-tabs__item:first-child)
    border-left: none!important
::v-deep(.el-tabs__item.is-active)
    color: #fff!important
    background-color: #4578FF!important
::v-deep(.el-tabs--card>.el-tabs__header .el-tabs__nav)
    border: 1px solid #e4e7ed!important
.search_result
    margin-top: 10px
</style>
