<template>
    <div class="case_wrapper">
        <div class="left" style="min-height: 500px">
            <SideMenu
                :menu_tree="menu_tree"
                @change="handleMenuChange"
                @toggle="handleToggle"
            />
        </div>
        <!-- 面包屑 -->
        <el-breadcrumb
            id="bread"
            separator-icon="ArrowRight"
            style="
                position: absolute;
                left: 256px;
                background-color: rgb(236, 236, 236);
                padding: 10px 5px;
                width: 100%;
            "
        >
            <el-breadcrumb-item :to="{ path: '/' }" @click="BackToCase"
                >{{ $t('msg.bread_current') }}：{{
                    $t('msg.case_list')
                }}</el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item
                >{{ $t('msg.bread_current') }}：{{
                    $t('msg.case_list')
                }}</el-breadcrumb-item
            > -->
            <el-breadcrumb-item v-if="caseNo">{{
                $t('msg.case') + ':' + caseNo
            }}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 办案管理开始 -->
        <div
            class="right_wrapper"
            style="padding: 0"
            id="case"
            v-show="right_type === 'case'"
        >
            <!-- 新------大型导航栏开始 -->
            <LargeNavigationBar
                v-if="$store.state.loginInfo.role === 2 || OrType"
                @openBind="openBind"
                @goPages="goPages"
                :showNumObj="showNumObj"
            ></LargeNavigationBar>
            <!-- 新------大型导航栏结束 -->

            <!-- 搜索与绑定 -->
            <div class="search">
                <img src="../assets/image/case/num.png" alt="" />
                <span>{{ $t('msg.case') }}NO：</span>
                <el-input
                    v-model="case_num"
                    size="small"
                    clearable
                    :placeholder="$t('msg.case')"
                    @clear="hideResult"
                ></el-input>
                <div class="btn_group">
                    <el-button @click="handleSearch">
                        <template #icon
                            ><img src="../assets/image/case/search.png" alt=""
                        /></template>
                        {{ $t('msg.case_search') }}
                    </el-button>
                </div>
            </div>
            <div v-show="show_result" class="search_result">
                <SearchResult ref="sr" />
            </div>
            <!-- 案件表格 -->
            <el-tabs
                v-show="!show_result"
                style="margin-top: 20px"
                v-model="activeName"
                type="card"
                @tab-click="handleClick"
            >
                <el-tab-pane
                    v-for="(item, index) in case_tabs"
                    :label="item.name"
                    :name="index.toString()"
                    :key="index"
                >
                    <TabList :ref="setItemRef" />
                </el-tab-pane>
                <!--
        <el-tab-pane :label="$t('msg.case_my')" name="我的案件">
          <TabList ref="tab1"/>
        </el-tab-pane>
        -->
            </el-tabs>
            <!-- 绑定案件弹出框 -->
            <el-dialog
                width="500px"
                v-model="dialogFormVisible"
                :title="$t('msg.case_bind_dialog_title')"
            >
                <el-form
                    :model="bindModel"
                    :rules="bindRules"
                    ref="bindModel"
                    style="padding-right: 50px"
                >
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-form-item
                                :label="$t('msg.case_num')"
                                label-width="120px"
                                prop="bindCode"
                            >
                                <el-input
                                    v-model="bindModel.bindCode"
                                    autocomplete="off"
                                    id="bindCode"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="cancel">{{
                            $t('msg.case_bind_cancel')
                        }}</el-button>
                        <el-button type="primary" @click="confirm">{{
                            $t('msg.case_bind_confirm')
                        }}</el-button>
                    </div>
                </template>
            </el-dialog>
        </div>

        <!-- 仲裁员 开始-->
        <div
            class="right_wrapper"
            id="arbitramentSignature"
            v-show="right_type === 'arbitramentSignature'"
        >
            <ArbitramentSignature ref="as" />
        </div>

        <div
            class="right_wrapper"
            id="declarationSignature"
            v-show="right_type === 'declarationSignature'"
        >
            <DeclarationSignature ref="ds" />
        </div>

        <!-- 送达记录   开始  -->
        <div
            class="right_wrapper"
            id="file"
            v-show="right_type === 'ReceiveLetterDisplay'"
        >
            <ReceiveLetterDisplay ref="ReceiveLetterDisplayRef" />
        </div>
        <!-- 送达记录   结束  -->

        <!-- 电子文件管理 -->
        <div class="right_wrapper" id="file" v-show="right_type === 'file'">
            <Finder :config="finderOptions" ref="finder" />
        </div>
        <!-- 电子文件管理 -->

        <!-- 电子文件上传 -->
        <div class="right_wrapper" id="submit" v-show="right_type === 'submit'">
            <MaterialUpload ref="mu" />
        </div>
        <!-- 电子文件上传 -->

        <!-- 查看案件进程 -->
        <div
            class="right_wrapper"
            id="caseProgress"
            v-show="right_type === 'caseProgress'"
        >
            <CaseProgress ref="cp" />
        </div>
        <!-- 查看案件进程 -->

        <!-- 上传操作日志  -->
        <div
            class="right_wrapper"
            id="uploadOperationLog"
            v-show="right_type === 'uploadOperationLog'"
        >
            <uploadOperationLog ref="up" />
        </div>
        <!-- 上传操作日志  -->

        <!-- 历史案件查询  -->
        <div
            class="right_wrapper"
            id="HistoryFileQuery"
            v-show="right_type === 'HistoryFileQuery'"
        >
            <HistoryFileQuery
                ref="HistoryFileQueryRef"
                @gomyCaseList="gomyCaseList"
            />
        </div>
        <!-- 历史案件查询  -->

        <!-- 报酬查询  -->
        <div
            class="right_wrapper"
            id="RemunerationQuery"
            v-show="right_type === 'RemunerationQuery'"
        >
            <RemunerationQuery
                ref="RemunerationQueryRef"
                @gomyCaseList="gomyCaseList"
            />
        </div>
        <!-- 报酬查询  -->

        <!-- 开庭日历  -->
        <div
            class="right_wrapper"
            id="RemunerationQuery"
            v-show="right_type === 'TrialCalendar'"
        >
            <TrialCalendar
                ref="TrialCalendarRef"
                @gomyCaseList="gomyCaseList"
            />
        </div>
        <!-- 开庭日历  -->

        <!-- 文书模版下载  -->
        <div
            class="right_wrapper"
            id="RemunerationQuery"
            v-show="right_type === 'DocumentTemplate'"
        >
            <DocumentTemplate
                ref="DocumentTemplateRef"
                @gomyCaseList="gomyCaseList"
            />
        </div>
        <!-- 文书模版下载  -->

        <!-- 线上开庭  -->
        <div
            class="right_wrapper"
            id="RemunerationQuery"
            v-show="right_type === 'OnHearing'"
        >
            <OnHearing ref="OnHearingRef" @gomyCaseList="gomyCaseList" />
        </div>
        <!-- 线上开庭  -->

        <!-- 扫描文件管理  -->
        <div
            class="right_wrapper"
            id="RemunerationQuery"
            v-show="right_type === 'scanFileManage'"
        >
            <ScanFileManage ref="scanFileManageRef" />
        </div>
        <!-- v扫描文件管理  -->

        <!-- 声明书披露  -->
        <div
            class="right_wrapper"
            id="StatementPublish"
            v-show="right_type === 'statementPublish'"
        >
            <StatementPublish ref="StatementPublishRef" />
        </div>
        <!-- 声明书披露  -->

        <!-- 上传裁决书  -->
        <div
            class="right_wrapper"
            id="StatementPublish"
            v-show="right_type === 'UploadAward'"
        >
            <UploadAward ref="UploadAwardRef" />
        </div>
        <!-- 上传裁决书  -->

        <!-- 延裁信息  -->
        <div
            class="right_wrapper"
            id="StatementPublish"
            v-show="right_type === 'delayJudgementInfo'"
        >
            <ChangeDelayJudgementInfo ref="delayJudgementInfoRef" />
        </div>
        <!-- 延裁信息  -->
        <!-- 开庭信息  -->
        <div
            class="right_wrapper"
            id="StatementPublish"
            v-show="right_type === 'courtInfo'"
        >
            <CourtInfo ref="courtInfoRef" />
        </div>
        <!-- 开庭信息  -->

        <!-- 仲裁员 结束-->

        
          <!-- 特殊程序指令 -->
          <div
            class="right_wrapper"
            id="SendFileToParty"
            v-show="right_type === 'SendFileToParty'"
        >
            <SendFileToParty ref="SendFileToPartyRef"></SendFileToParty>
        </div>

        <!-- 特殊程序指令------------ -->

        <div class="right_wrapper" id="empty" v-show="right_type === 'empty'">
            <el-empty description="没有数据" style="margin: 50px auto" />
        </div>
    </div>
</template>

<script>
//新增菜单，第一步在template中曾加div显示菜单。第二步在import此处导入自己新建的组件，第三步，在mothods增加方法
import SideMenu from '@/components/SideMenu'
import TabList from '@/components/case/TabList'
import Finder from '@/pages/Finder'
import SearchResult from '@/components/case/SearchResult'
import ArbitramentSignature from '@/components/case/ArbitramentSignature'
import DeclarationSignature from '@/components/case/DeclarationSignature'
import CaseProgress from '@/components/case/CaseProgress' //查看案件进程
import MaterialUpload from '@/components/MaterialUpload'
// 大的导航栏
import LargeNavigationBar from './LargeNavigationBar.vue'
import ReceiveLetterDisplay from './case/ReceiveLetterDisplay.vue'
import UploadOperationLog from './UploadOperationLog.vue'

// 历史案件查询
import HistoryFileQuery from '@/pages/arb/HistoryFileQuery.vue'
// 报酬查询
import RemunerationQuery from '@/pages/arb/RemunerationQuery.vue'
// 开庭日历
import TrialCalendar from '@/pages/arb/TrialCalendar.vue'
// 文书模版下载
import DocumentTemplate from '@/pages/arb/DocumentTemplate.vue'
// 网上开庭
import OnHearing from './OnHearing.vue'
// 扫描文件管理
import ScanFileManage from '../pages/arb/ScanFileManage.vue'
// 声明书披露
import StatementPublish from '../pages/arb/StatementPublish.vue'
// 上传裁决书
import UploadAward from '../pages/arb/UploadAward.vue'
// 延裁信息
import ChangeDelayJudgementInfo from '../pages/arb/ChangeDelayJudgementInfo.vue'
// 开庭信息
import CourtInfo from '../pages/arb/ChangCourtInfo.vue'

// 特殊程序指令
import SendFileToParty from '../pages/SendFileToParty.vue'

// import searchGuidePagesVue from './searchGuidePages.vue'
//在export中别忘了新加的菜单项
export default {
    name: 'CaseList',
    components: {
        SideMenu,
        TabList,
        Finder,
        SearchResult,
        ArbitramentSignature,
        DeclarationSignature,
        LargeNavigationBar,
        ReceiveLetterDisplay,
        MaterialUpload,
        CaseProgress,
        UploadOperationLog,
        HistoryFileQuery,
        RemunerationQuery,
        TrialCalendar,
        DocumentTemplate,
        OnHearing,
        ScanFileManage,
        StatementPublish,
        UploadAward,
        ChangeDelayJudgementInfo,
        CourtInfo,
        SendFileToParty
    },
    created() {
        // this.GetCaseTabs()
        // 监听点击案件名称事件 设置当前case_id
        this.$listener.$on('case_change', (row) => {
            this.right_type = 'file'
            this.$nextTick(() => {
                this.$refs.finder?.load(row.caseId)
            })
            this.case_id = row.caseId
            this.caseNo = row.caseNo
        })
        this.$listener.$on('lang', () => {
            this.case_tabs = this.$GF.langChangeForTab(this.case_tabs)
            this.activeName = '0'
        })
    },
    props: {
        menu_tree: {
            type: Array,
            default() {
                return []
            }
        },
        activeId: {
            type: String
        }
    },
    data() {
        return {
            cur: '1',
            case_num: '',
            activeName: '我的案件',
            dialogFormVisible: false,
            bindModel: {
                bindCode: '',
                nation: '',
                register_type: ''
            },
            bindRules: {
                bindCode: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.$GF.formValidator
                    }
                ]
            },
            case_tabs: [],
            tab_loading: false,
            show_result: false,
            right_type: 'case', // case、file、submit、progress、uploadOperationLog、arbitramentSignature、declarationSignature、empty
            finderOptions: {
                lang: false
            },
            case_id: '',
            refList: [],
            caseNo: '',
            receiveDetailInfo: {},
            // 登记状态页+二维码页面
            receiveTypeData: '',
            // 消息中心的未读数量
            showNumObj: {},
            // 画图页面是否显示状态
            OrType: false
        }
    },
    methods: {
        async load() {
            this.GetCaseTabs()
            this.getMessageCount()
            this.getShowOrNoneArbitration()
            this.right_type = 'case'
            this.caseNo = ''
        },
        handleMenuChange(menu_index) {
            console.log(menu_index)
            // 点击案件后的菜单
            //经办人菜单
            if (menu_index === 'materialSubmitLog') {
                // 材料提交
                this.right_type = 'submit'
                this.$refs.mu.load(this.case_id)
            } else if (menu_index === 'materialManage') {
                // 文件管理器
                this.right_type = 'file'
                this.$refs.finder.load(this.case_id)
            } else if (menu_index === 'myCaseList') {
                // 案件列表
                this.right_type = 'case'
                this.load()
            } else if (menu_index === 'caseProgress') {
                // 案件进程
                this.right_type = 'caseProgress'
                this.$refs.cp.load(this.case_id)
            } else if (menu_index === 'uploadOperationLog') {
                // 上传操作日志
                this.right_type = 'uploadOperationLog'
                this.$refs.up.load(this.case_id)
            } else if (menu_index === 'arbitramentSignature') {
                // 裁决书签字
                this.right_type = 'arbitramentSignature'
                this.$refs.as.load(this.case_id)
            } else if (menu_index === 'declarationSignature') {
                // 声明书签字
                this.right_type = 'declarationSignature'
                this.$refs.ds.load(this.case_id)
            } else if (menu_index === 'receiveLetterDisplay') {
                // 声明书签字
                this.right_type = 'ReceiveLetterDisplay'
                this.$refs.ReceiveLetterDisplayRef.load(this.case_id)
            } else if (menu_index === 'OnHearing') {
                // 线上开庭
                this.right_type = 'OnHearing'
                this.$refs.OnHearingRef.load(this.case_id)
            } else if (menu_index === 'scanFileManage') {
                // 文件管理
                this.right_type = 'scanFileManage'
                this.$refs.scanFileManageRef.load(this.case_id)
            } else if (menu_index === 'statementPublish') {
                // 线上开庭
                this.right_type = 'statementPublish'
                this.$refs.StatementPublishRef.load(this.case_id)
            } else if (menu_index === 'uploadAward') {
                // 上传裁决书
                this.right_type = 'UploadAward'
                this.$refs.UploadAwardRef.load(this.case_id, this.caseNo)
            } else if (menu_index === 'changeDelayJudgementInfo') {
                // 延裁信息
                this.right_type = 'delayJudgementInfo'
                this.$refs.delayJudgementInfoRef.load(this.case_id)
            } else if (menu_index === 'changCourtInfo') {
                // 开庭信息
                this.right_type = 'courtInfo'
                this.$refs.courtInfoRef.load(this.case_id)
            } else if (menu_index === 'sendFileToParty') {
                // 特殊程序指令
                this.right_type = 'SendFileToParty'
                this.$refs.SendFileToPartyRef.load(this.case_id)
            } else {
                this.$message.warning('资源不存在')
                this.right_type = 'empty'
            }
        },
        handleData(data) {
            // 子传参到父组件
            this.receiveDetailInfo = data
            this.right_type = 'receiveDetail'
        },
        receiveData(data) {
            // 子传参到父组件
            this.receiveTypeData = data
            this.right_type = 'receiveType'
        },
        handleClick(tab) {
            // console.log('tab', tab)
            this.activeName = tab.index
            this.refList[tab.index].load(
                this.case_tabs[tab.index].sign,
                this.case_tabs[tab.index].caseCount
            )
        },
        openBind() {
            this.dialogFormVisible = true
        },
        async GetCaseTabs() {
            this.tab_loading = true
            const res = await this.$API.sendRequest('CaseTabs')
            if (res) {
                this.case_tabs = this.$GF.langChangeForTab(res.data)
                this.activeName = '0'
                await this.$nextTick(() => {
                    this.refList[0].load(
                        this.case_tabs[0].sign,
                        this.case_tabs[0].caseCount
                    )
                })
            }
            this.tab_loading = false
        },
        cancel() {
            this.dialogFormVisible = false
            this.$refs.bindModel.resetFields()
        },
        confirm() {
            this.$refs.bindModel.validate(async (valid) => {
                if (valid) {
                    const param = {
                        req: {
                            bindCode: this.bindModel.bindCode
                        }
                    }
                    const res = await this.$API.sendRequest('BindCase', param)
                    if (res && res.success) {
                        this.$message.success('绑定成功')
                        this.cancel()
                        // this.refList[0].load(1)
                        this.load()
                    } else {
                        this.$message.error(res.msg)
                    }
                }
            })
        },
        handleSearch() {
            console.log(this.case_num, 'this.case_num')
            const lang = localStorage.getItem('lang')
            if (!this.case_num)
                return this.$message.warning(
                    lang === 'en'
                        ? 'Please enter the case number'
                        : '请输入案件编号'
                )
            this.show_result = true
            this.$refs.sr.load(this.case_num)
        },
        hideResult() {
            this.show_result = false
            this.$refs.sr.reset()
        },
        // 右侧宽度自适应
        handleToggle(val) {
            console.log(val, '右侧宽度')

            const p_width = document.querySelector('.case_wrapper').offsetWidth
            const id = `#${this.right_type}`
            if (val) {
                document.querySelector(id).style.width = p_width - 10 + 'px'
                document.querySelector('#bread').style.width =
                    p_width - 10 + 'px'
                document.querySelector('#bread').style.left = '70px'
            } else {
                document.querySelector(id).style.width = p_width - 258 + 'px'
                document.querySelector('#bread').style.width =
                    p_width - 258 + 'px'
                document.querySelector('#bread').style.left = '256px'
            }
        },
        setItemRef(el) {
            this.refList.push(el)
        },
        BackToCase() {
            this.$emit('back')
            this.load()
            this.caseNo = ''
        },

        // 返回当前页面
        returnPages() {
            this.right_type = 'case'
            this.load()
        },

        // 消息展示数量请求
        async getMessageCount() {
            let res = await this.$API.sendRequest('ShowMessageCount')
            this.showNumObj = res.data
        },
        // 判断画图界面是否显示
        async getShowOrNoneArbitration() {
            let res = await this.$API.sendRequest('ShowOrNoneArbitration')
            this.OrType = res.data
        },

        // ----------------------------跳转页面 需要的格式obj:{type:'需要跳转的right_type',ref:'需要进入就调用load函数'},其余的另做处理
        goPages(obj) {
            this.right_type = obj.type
            this.$refs[obj.ref].load()
        },
        // ----------------------------跳转页面

        // 返回当前页面myCaseList
        gomyCaseList() {
            this.right_type = 'case'
            this.load()
        }
    },
    watch: {
        case_num: function (val) {
            if (val === '') this.show_result = false
        },
        activeId: function (val) {
            if (val === '15') {
                this.right_type = 'case'
            } else if (val === '16') {
                this.right_type = 'receive'
            }
        }
    }
}
</script>

<style scoped lang="sass">
.case_wrapper
    display: flex
    gap: 5px
    padding: 2px
.right_wrapper
    width: 100%
    //height: calc(100vh - 145px)
    margin-top: 40px
    ::v-deep(.el-breadcrumb__inner)
        color: #7F96CD!important
    .search
        margin-top: 20px
        height: 80px
        box-shadow: 0 0 2px 1px #E1EAFA
        display: flex
        font-size: 13px
        align-items: center
        padding: 0 20px 0 10px
    img
        width: 24px
        height: 24px
    span
        width: 180px
    .btn_group
        width: 360px
        img
            width: 13px
            height: 13px
    ::v-deep(.el-input__wrapper)
        height: 35px!important
::v-deep(.el-tabs__item)
    color: #434A54!important
    border-radius: 3px 3px 0 0
    border-left: 1px solid #e4e7ed!important
    border-bottom: none!important
::v-deep(.el-tabs--card>.el-tabs__header .el-tabs__item:first-child)
    border-left: none!important
::v-deep(.el-tabs__item.is-active)
    color: #fff!important
    background-color: #4578FF!important
::v-deep(.el-tabs--card>.el-tabs__header .el-tabs__nav)
    border: 1px solid #e4e7ed!important
.search_result
    margin-top: 10px
</style>
