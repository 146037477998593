<template>
    <div v-loading="loading">
        <!--  特殊程序指令 -->
        <div class="titleBox">
            <h3>{{ $t('msg.SpecialProgramInstructions') }}</h3>
        </div>
        <div class="addressBox">
            <el-row style="margin-bottom: 20px">
                <el-col :span="6">
                    <el-input
                        v-model="fileName"
                        clearable
                        :placeholder="$t('msg.PleaseenteraFileName')"
                        @keydown.enter="getDisplayReplyLetter"
                    ></el-input>
                </el-col>
                <el-col :span="4">
                    <el-button @click="getDisplayReplyLetter">{{
                        $t('msg.finder_search')
                    }}</el-button>
                </el-col>
                <el-col :span="14"></el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <!-- <el-table-column prop="dspAttName" :label="$t('msg.FileName')">         </el-table-column>-->

                <el-table-column prop="dspAttName" :label="$t('msg.FileName')">
                    <template #default="scope">
                        <el-dropdown trigger="hover">
                            <span class="el-dropdown-link">
                                <p
                                    :class="{
                                        redText: scope.row.doAction
                                            ? true
                                            : false
                                    }"
                                >
                                    {{ scope.row.dspAttName }}
                                    <el-icon :size="size">
                                        <ArrowDown />
                                    </el-icon>
                                </p>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item
                                        @click="PreviewPdfClick(scope.row)"
                                        >{{
                                            $t('msg.Preview')
                                        }}</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        @click="PdfUpload(scope.row)"
                                        >{{
                                            $t('msg.Download')
                                        }}</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="libTypeName"
                    :label="$t('msg.MaterialType')"
                >
                </el-table-column>
                <el-table-column prop="subDate" :label="$t('msg.DateofUpload')">
                </el-table-column>
                <el-table-column prop="remark" :label="$t('msg.Note')">
                </el-table-column>

                <!-- <el-table-column prop="title" :label="$t('msg.Titleofpost')">
                </el-table-column>

                <el-table-column
                    prop="materialTypeName"
                    :label="$t('msg.TypeofPosting')"
                >
                </el-table-column>
                <el-table-column prop="fileName" :label="$t('msg.FileName')">
                    <template #default="scope">
                        <el-dropdown trigger="hover">
                            <span class="el-dropdown-link">
                                <p
                                    :class="{
                                        redText: scope.row.flagMessage
                                            ? true
                                            : false
                                    }"
                                >
                                    {{ scope.row.fileName }}
                                    <el-icon :size="size">
                                        <ArrowDown />
                                    </el-icon>
                                </p>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item
                                        @click="PreviewPdfClick(scope.row)"
                                        >{{
                                            $t('msg.Preview')
                                        }}</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        @click="PdfUpload(scope.row)"
                                        >{{
                                            $t('msg.Download')
                                        }}</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="uploadDate" :label="$t('msg.Date')">
                </el-table-column> -->
            </el-table>
            <div
                class="demo-pagination-block"
                style="margin-top: 10px; display: flex; justify-content: center"
            >
                <!-- 分页 -->
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
 
<script>
export default {
    name: 'todoList',
    components: {},
    data() {
        return {
            loading: false,
            tableData: [],
            current: 1,
            size: 10,
            total: 0,
            // 搜索框的值
            fileName: '',
            currentPage4: 1,
            caseId: '',
            // 下载枚举
            downEnum: {
                '.gif': 'image/gif',
                '.jpg': 'image/jpeg',
                '.jpeg': 'image/jpeg',
                '.png': 'image/png',
                '.doc': 'application/msword',
                '.docx':
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                '.xlsx':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                '.pdf': 'application/pdf',
                '.rar': 'application/x-rar-compressed',
                '.zip': 'application/zip'
            }
        }
    },
    methods: {
        async load(caseId) {
            console.log(caseId)
            this.caseId = caseId
            this.loading = true
            this.current = 1
            this.size = 10
            this.fileName = ''
            await this.getDisplayReplyLetter()
            this.loading = false
        },
        // 获取列表
        async getDisplayReplyLetter() {
            let param = {
                req: {
                    current: this.current,
                    size: this.size,
                    q: {
                        caseId: this.caseId,
                        fileName: this.fileName
                    }
                }
            }
            let res = await this.$API.sendRequest('shoFileToPartyList', param)
            if (!res.success) {
                return this.$message.error(res.msg)
            }
            this.total = res.data.total
            this.tableData = res.data.records
        },
        // 改变size
        handleSizeChange(val) {
            this.size = val
            this.getDisplayReplyLetter()
        },
        // 改变current
        handleCurrentChange(val) {
            this.current = val
            this.currentPage4 = val
            this.getDisplayReplyLetter()
        },

        // 预览
        // 点击预览pdf
        async PreviewPdfClick(row) {
            this.loading = true
            let params = {
                req: {
                    attId: row.attId,
                    caseId: this.caseId
                }
            }
            let res = await this.$API.sendRequest(
                'showPreOrDownFileParty',
                params
            )
            let blob
            let fileType = this.$GF.pdfFlagFn(row.dspAttName)
            // console.log(fileType)
            // if (fileType === 1) {
            //     blob = new Blob([res], { type: 'application/pdf' })
            // } else if (fileType === 2) {
            //     blob = new Blob([res], { type: 'application/pdf' })
            // }

            if (fileType === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
            } else if (fileType === 0) {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
            } else {
                let fileType = row.fileName.substring(
                    row.fileName.lastIndexOf('.')
                )
                for (let key in this.downEnum) {
                    if (key === fileType) {
                        let blob = new Blob([res], { type: this.downEnum[key] })
                        let url = window.URL.createObjectURL(blob)
                        let link = document.createElement('a')
                        link.href = url
                        link.download = row.dspAttName
                        link.click()
                        window.URL.revokeObjectURL(link.href)
                    }
                }
                return
            }

            let url = window.URL.createObjectURL(blob)
            window.open(url)
            this.getDisplayReplyLetter()
            this.loading = false
        },

        // 点击下载pdf
        async PdfUpload(row) {
            this.loading = true
            let params = {
                req: {
                    attId: row.attId,
                    caseId: this.caseId
                }
            }
            let res = await this.$API.sendRequest(
                'showPreOrDownFileParty',
                params
            )

            if (res.success === false) {
                this.loading = false
                this.$message.error('下载失败')
                return
            }
            let fileType = row.dspAttName.substring(
                row.dspAttName.lastIndexOf('.')
            )
            for (let key in this.downEnum) {
                if (key === fileType) {
                    let blob = new Blob([res], { type: this.downEnum[key] })
                    let url = window.URL.createObjectURL(blob)
                    let link = document.createElement('a')
                    link.href = url
                    link.download = row.dspAttName
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                }
            }
            // let url = window.URL.createObjectURL(blob)
            // let link = document.createElement('a')
            // link.href = url
            // link.download = row.dspAttName
            // link.click()
            // window.URL.revokeObjectURL(link.href)
            this.getDisplayReplyLetter()
            this.loading = false
        }
    }
}
</script>
 
<style scoped lang="scss">
.titleBox {
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        position: relative;
        top: 0;
        font-size: 16px;
        padding-left: 10px;
        &::before {
            content: '';
            display: block;
            height: 25px;
            width: 4px;
            background: #ff0000;
            position: absolute;
            top: -3px;
            left: 2px;
        }
    }
    .butBox {
        margin-right: 20px;
        display: flex;
        align-self: center;
    }
}

.redText {
    color: red;
}
.el-dropdown-link {
    color: #6cb4ff;
}
</style>