<template>
    <div v-loading="agentDialog">
        <el-form :model="agentInfo" :rules="partyInfoRules" class="partyForm" ref="partyFormRef">
            <!-- 添加申请人信息 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4>
                        {{
                            type === 1
                                ? $t('msg.Addapplicantagentinformation')
                                : $t('msg.AddTheDefendantInformation')
                        }}
                    </h4>
                    <el-divider></el-divider>
                    <!-- 委托人  -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.Client') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-checkbox-group v-model="partyIdList">
                                <el-checkbox :disabled="!allType" v-for="(item, index) in getAgentList" :key="item"
                                    :label="item.partyId" size="large">
                                    {{ index + 1 }}.
                                    {{ item.partyCompany }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-col>
                    </el-row>
                    <!-- 代理人姓名 -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.AgentsName') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="deputyName">
                                <!-- <el-input :placeholder="$t('msg.PleaseEntertheAgentName')"
                                    v-model.trim="agentInfo.deputyName" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%"  :disabled="!allType"
                                        v-model.trim="agentInfo.deputyName" :trigger-on-focus="false"
                                        :fetch-suggestions="agentInfoDeputyNameList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEntertheAgentName')"></el-autocomplete>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 国籍 -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.Nationality') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="nationality">
                                <el-select :disabled="!allType" v-model="agentInfo.nationality" filterable
                                    :placeholder="$t('msg.PleaseSelect')" style="width: 100%"
                                    @change="countrySelectChange">
                                    <el-option v-for="(
                                            item, index
                                        ) in getCountriesList" :key="index" :label="item.dictionaryItemName"
                                        :value="item.dictionaryItemValue" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 选择证件类型  -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.IDType') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="idType">
                                <el-select :disabled="!allType" v-model="agentInfo.idType" filterable
                                    :placeholder="$t('msg.PleaseSelect')" style="width: 100%">
                                    <el-option v-for="(
                                            item, index
                                        ) in newGetDictionaryLtemsList" :key="index" :label="item.dictionaryItemName"
                                        :value="item.dictionaryItemValue" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 证件号码  -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.IDNo') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="idNum" v-if="agentInfo.idType === 1">
                                <!-- <el-input :placeholder="$t('msg.PleaseEnterTheIDNumber')" v-model.trim="agentInfo.idNum"
                                    :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%"  :disabled="!allType"
                                        v-model.trim="agentInfo.idNum" :trigger-on-focus="false"
                                        :fetch-suggestions="agentInfoIdNumList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEnterTheIDNumber')"></el-autocomplete>
                            </el-form-item>
                            <el-form-item v-else prop="idNum2">
                                <!-- <el-input :placeholder="$t('msg.PleaseEnterTheIDNumber')"
                                    v-model.trim="agentInfo.idNum2" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%"  :disabled="!allType"
                                        v-model.trim="agentInfo.idNum2" :trigger-on-focus="false"
                                        :fetch-suggestions="agentInfoIdNumList" :debounce="500"
                                        :placeholder="$t('msg.PleaseEnterTheIDNumber')"></el-autocomplete>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!-- 添加联系方式 -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <!-- 联系电话  -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.ContactNumber') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="phone">
                                <!-- <el-input :placeholder="$t('msg.PleaseenteryourcontactNumber')"
                                    v-model.trim="agentInfo.phone" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%"  :disabled="!allType"
                                        v-model.trim="agentInfo.phone" :trigger-on-focus="false"
                                        :fetch-suggestions="agentInfoPhoneList" :debounce="500"
                                        :placeholder="$t('msg.PleaseenteryourcontactNumber')"></el-autocomplete>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 电子邮箱  -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.Email') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="email">
                                <!-- <el-input :placeholder="$t('msg.PleaseenteryourEmailAddress')"
                                    v-model.trim="agentInfo.email" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%"  :disabled="!allType"
                                        v-model.trim="agentInfo.email" :trigger-on-focus="false"
                                        :fetch-suggestions="agentInfoEmailList" :debounce="500"
                                        :placeholder="$t('msg.PleaseenteryourEmailAddress')"></el-autocomplete>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 单位名称 -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.Company') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="deputyCompany">
                                <!-- <el-input :placeholder="$t('msg.Pleaseenterthenameoftheorganization')"
                                    v-model.trim="agentInfo.deputyCompany" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%"  :disabled="!allType"
                                        v-model.trim="agentInfo.deputyCompany" :trigger-on-focus="false"
                                        :fetch-suggestions="agentInfoDeputyCompanyList" :debounce="500"
                                        :placeholder="$t('msg.Pleaseenterthenameoftheorganization')"></el-autocomplete>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 选择地址 -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.SelectAddress') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <div class="selectBox">
                                <el-form-item prop="area">
                                    <!-- 省  -->
                                    <el-select filterable :placeholder="$t('msg.PleaseSelect')" v-model="agentInfo.area"
                                        @change="areaNameChange" :disabled="!allType">
                                        <el-option v-for="(
                                                item, index
                                            ) in areaNameSelectList" :key="index" :label="item.areaName"
                                            :value="item.areaId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 市  -->
                                <el-form-item prop="deputyCity" style="
                                        margin-left: 20px;
                                        margin-right: 20px;
                                    ">
                                    <el-select filterable :placeholder="$t('msg.PleaseSelect')"
                                        v-model="agentInfo.deputyCity" @change="marketChange" :disabled="!allType">
                                        <el-option v-for="(item, index) in marketList" :key="index"
                                            :label="item.areaName" :value="item.areaId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <!-- 县  -->
                                <el-form-item prop="district">
                                    <el-select filterable :placeholder="$t('msg.PleaseSelect')"
                                        v-model="agentInfo.district" :disabled="!allType">
                                        <el-option v-for="(item, index) in countyList" :key="index"
                                            :label="item.areaName" :value="item.areaId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>

                    <!-- 详细地址 -->
                    <el-row>
                        <el-col :span="4">
                            <p>
                                <span class="requireIconBox">{{ $t('msg.DetailedAddress') }}
                                    <span>
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-bitian"></use>
                                        </svg></span>
                                </span>
                            </p>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="deputyCompany">
                                <!-- <el-input :placeholder="$t('msg.PleaseentertheAddressDetails')"
                                    v-model.trim="agentInfo.companyAddress" :disabled="!allType"></el-input> -->
                                    <el-autocomplete style="width: 100%"  :disabled="!allType"
                                        v-model.trim="agentInfo.companyAddress" :trigger-on-focus="false"
                                        :fetch-suggestions="agentInfoCompanyAddressList" :debounce="500"
                                        :placeholder="$t('msg.PleaseentertheAddressDetails')"></el-autocomplete>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!-- 提示文本 -->
            <div class="cardTextBox">
                <el-card>
                    <p>
                        1.
                        {{ $t('msg.Whenuploadingilhangenheattorneyletteretc') }}
                    </p>
                    <p>
                        2.
                        {{ $t('msg.Ifthepartibitratiothorizationpecialauthorization') }}
                    </p>
                    <p>
                        3.
                        {{ $t('msg.Alldocumentssubmittedbythepaneeformebiroceedings') }}
                    </p>
                    <p>
                        4.
                        {{ $t('msg.IftheclieongMacaoaiwanptorsdbesubmtted') }}
                    </p>

                    <p>
                        {{ $t('msg.ReferenceTemplate') }}:
                        <span @click="downloadDoc(1)">{{ $t('msg.TemplateofPowerofAttorney') }}</span>
                    </p>
                </el-card>
            </div>

            <!-- 添加主体资格证明材料  -->
            <div class="partyBox">
                <div class="partyInfoBox">
                    <h4 style="display: flex; align-items: center">
                        {{ $t('msg.AddthepowerofAttorney') }}
                        <el-upload :file-list="fileList" class="upload-demo" action="#" multiple :auto-upload="false"
                            :show-file-list="false" :on-change="partyUploadProgress" :accept="acceptedFormats">
                            <el-button style="margin-left: 20px" :disabled="!allType">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-shenqing"></use>
                                </svg>{{ $t('msg.AddAttachment') }}</el-button>
                        </el-upload>
                        <span style="font-size: 14px; font-weight: 500">
                            ({{ $t('msg.Thesizeoftheuploadedfiledoenotexceed') }} {{
                                this.$store.state.uploadSize
                            }}MB)</span>
                    </h4>

                    <el-divider></el-divider>
                    <!-- 材料类型表格  -->

                    <!-- 展示表头的表格 -->
                    <el-table :border="true" style="width: 100%">
                        <el-table-column prop="fileName" :label="$t('msg.NameoftheMaterial')" />
                        <el-table-column prop="address" :label="$t('msg.Operate')" />
                    </el-table>

                    <!-- 材料类型       待上传        表格  -->

                    <el-table v-if="myMaterialList.length !== 0" :data="myMaterialList" :border="true"
                        style="width: 100%" :show-header="false">
                        <el-table-column prop="name" :label="$t('msg.NameoftheMaterial')" />
                        <el-table-column prop="address" :label="$t('msg.Operate')">
                            <template #default="scope">
                                <el-button link type="primary" size="small" @click="clickViewPreview(scope)">{{
                                    $t('msg.View') }}</el-button>
                                <el-button link type="danger" size="small" @click="deleteMyMaterialList(scope, 'M')">{{
                                    $t('msg.Delete') }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 材料类型       已添加      表格  -->
                    <el-table :data="ShowFileList" :border="true" style="width: 100%" :show-header="false"
                        v-if="ShowFileList.length !== 0">
                        <el-table-column prop="fileNameDsp" :label="$t('msg.NameoftheMaterial')" />
                        <el-table-column prop="address" :label="$t('msg.Operate')">
                            <template #default="scope">
                                <el-button link type="primary" size="small" @click="newclickViewPreview(scope.row)">{{
                                    $t('msg.View') }}</el-button>
                                <el-button link type="danger" size="small" v-if="allType"
                                    @click="deleteMyMaterialList(scope, 'S')">{{ $t('msg.Delete') }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row style="margin-top: 20px">
                        <el-col :span="20"></el-col>
                        <el-col :span="4"> </el-col>
                    </el-row>
                </div>
            </div>

            <!-- 上传文件进度  percentageNumType-->
            <el-row v-if="percentageNumType">
                <el-col :span="4">
                    <p style="text-align: right; padding-right: 10px">
                        {{ $t('msg.Uploadfileprogress') }}
                    </p>
                </el-col>
                <el-col :span="20" style="font-size: 16px">
                    <div class="progressBox">
                        <el-progress :percentage="percentageNum" style="width: 50%" />
                        <span :class="{
                            progressTextColor:
                                trueNum !== myMaterialList.length
                        }">
                            {{ trueNum }}</span>/{{ myMaterialList.length }}
                    </div>
                </el-col>
            </el-row>

            <!-- 保存按钮 -->
            <el-row>
                <div class="btn">
                    <el-button type="primary" @click="addPartySubmit" :loading="submitLoading" v-if="allType">{{
                        $t('msg.Save')
                        }}</el-button>
                </div>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'agentDialog',
    components: {},
    data() {
        return {
            // 添加申请人代理人弹窗
            agentDialog: false,
            // 当事人类型
            partyTypeList: [],
            // 自己选择上传的文件列表数组
            myMaterialList: [],
            // 上传的文件列表
            fileList: [],
            // 文件上传格式
            acceptedFormats:
                '.git,.jpg,.jpeg,.bmp,.doc,.xls,.docx,.xlsx,.pdf,.zip,.rar',

            // 添加申请人代理人国籍
            nationalitySelect: '',
            // 添加申请人选择非中国后选择国别数组
            getCountriesList: [],
            // 添加申请人弹窗加载效果
            partyDialogDialog: true,
            // 添加申请人信息
            agentInfo: {
                // 申请人名称
                deputyName: '',
                //国家/地区名称

                //国家/地区名称
                nationality: '',
                // 证件类型
                idType: '',
                // 身份证号
                idNum: '',

                // 手机号
                phone: '',
                // 电子邮箱
                email: '',
                // 邮编
                deputyZipcode: '',
                // 单位名称
                deputyCompany: '',
                // 省
                area: '',
                // 市
                deputyCity: '',
                // 县
                district: '',
                // 详细地址
                companyAddress: ''
            },
            partyInfoRules: {
                deputyName: [
                    {
                        required: true,
                        message: '请输入申请人姓名！',
                        trigger: 'blur'
                    }
                ],

                phone: [
                    {
                        required: true,
                        message: '请输入联系电话！',
                        trigger: 'blur'
                    },
                    {
                        pattern: '^1[3-9][0-9]{9}$',
                        message: '请输入正确的手机号',
                        trigger: 'blur'
                    }
                ],
                nationality: [
                    {
                        required: true,
                        message: '请选择国家/地区名称！',
                        trigger: 'change'
                    }
                ],
                idType: [
                    {
                        required: true,
                        message: '请选择证件类型！',
                        trigger: 'change'
                    }
                ],

                idNum: [
                    {
                        required: true,
                        message: '请输入身份证！',
                        trigger: 'blur'
                    },
                    {
                        pattern: '^(\\d{15}$|^\\d{18}$|^\\d{17}(\\d|X|x))$',
                        message: '请输入正确的身份证',
                        trigger: 'blur'
                    }
                ],
                idNum2: [
                    {
                        required: true,
                        message: '请输入证件号码！',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        required: true,
                        message: '请输入电子邮箱！',
                        trigger: 'blur'
                    },
                    {
                        pattern:
                            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
                        message: '请输入正确的邮箱格式',
                        trigger: 'blur'
                    }
                ],
                area: [
                    {
                        required: true,
                        message: '请输入省!',
                        trigger: 'change'
                    }
                ],
                deputyCity: [
                    {
                        required: true,
                        message: '请输入市!',
                        trigger: 'change'
                    }
                ],
                district: [
                    {
                        required: true,
                        message: '请输入县/区!',
                        trigger: 'change'
                    }
                ],
                deputyCompany: [
                    {
                        required: true,
                        message: '此项为必填',
                        trigger: 'blur'
                    }
                ]
            },
            // 保存按钮loading
            submitLoading: false,

            // 请求回来的数组委托人
            getAgentList: [],
            // 选择的数组委托人
            partyIdList: [],
            // 判断申请人是  申还是被
            type: 0,
            // caseId等等
            caseObj: {},
            // 展示的文件列表
            ShowFileList: [],
            // 点击删除的展示文件列表
            deleteShowFileList: [],
            // 全部的状态
            allType: false,
            // 上传文件后返回的文件识别id
            digitalId: [],
            // 进度条
            percentageNum: 0,
            // 进度条是否显示
            percentageNumType: false,
            // 上传的个数
            trueNum: 0,
            // 省数组
            areaNameSelectList: [],
            // 市数组
            marketList: [],
            // 县数组
            countyList: []
        }
    },
    methods: {
        async load(type, obj, res, allType) {
            this.$refs.partyFormRef.clearValidate()
            this.type = type
            this.caseObj = obj
            this.allType = allType
            this.agentDialog = true
            this.getDictionaryLtemsList = await this.GetDictionaryLtems(275)
            // 添加申请人选择非中国后选择国别
            this.getCountriesList = await this.GetDictionaryLtems(4)
            this.areaNameSelectList = await this.clickStyleTypeList()
            this.GetShowPartyInfo()
            // 以下代码是反显  开始
            if (res !== undefined) {
                this.agentInfo = {
                    ...res
                }
                if (this.agentInfo.idType !== 1) {
                    this.agentInfo.idNum2 = this.agentInfo.idNum
                }
                this.marketList = await this.clickStyleTypeList(
                    this.agentInfo.area
                )
                this.countyList = await this.clickStyleTypeList(
                    this.agentInfo.deputyCity
                )
                this.partyIdList = [...res.partyId]
                this.ShowFileList = this.agentInfo.fileListVos
            }
            // 以下代码是反显  结束
            this.agentDialog = false
        },

        // 退出清空表单事件
        clickResetFields() {
            this.agentInfo = {}
            this.partyIdList = []
            this.fileList = []
            this.myMaterialList = []
            this.ShowFileList = []
            this.deleteShowFileList = []
            this.digitalId = []
            this.percentageNum = 0
            this.percentageNumType = false
            this.$refs.partyFormRef.resetFields()
        },

        // 委托人类型方法
        async GetShowPartyInfo() {
            let param = {
                req: {
                    caseId: this.caseObj.caseId,
                    partyType: this.type
                }
            }
            let res = await this.$API.sendRequest('GetShowPartyInfo', param)
            this.getAgentList = res.data
        },

        // 上传的change事件，fileList更改触发
        partyUploadProgress() {
            let newFileList = []
            let uploadSize = this.$store.state.uploadSize
            for (let index = 0; index < this.fileList.length; index++) {
                const isLt100 =
                    this.fileList[index].size / 1024 / 1024 < uploadSize
                if (isLt100) {
                    newFileList.push(this.fileList[index])
                    continue
                } else {
                    this.$message.error(
                        '上传文件大小不能超过 ' + uploadSize + ' MB!'
                    )
                    this.fileList.splice(index, 1) // 从 fileList 中移除不满足条件的文件
                    index-- // 因为移除了一个元素，所以需要将 index 减 1
                    continue
                }
            }
            this.myMaterialList = newFileList
        },

        // 删除自己选择上传列表的数据
        deleteMyMaterialList(row, type) {
            if (type === 'M') {
                this.myMaterialList = this.myMaterialList.filter(
                    (item, index) => {
                        return index !== row.$index
                    }
                )
                this.fileList = this.myMaterialList
            } else {
                let showFileList = []
                showFileList = this.ShowFileList.filter((item, index) => {
                    if (index !== row.$index) {
                        return item
                    }
                })
                let deleteArr = this.ShowFileList.filter((item, index) => {
                    if (index === row.$index) {
                        return item
                    }
                })
                this.ShowFileList = showFileList
                this.deleteShowFileList.push(deleteArr[0])
            }
            // this.myMaterialList = this.fileList
        },

        // 提交申请人事件
        addPartySubmit() {
            this.$refs.partyFormRef.validate(async (valid) => {
                if (valid) {

                            // 判断本地有没有数据,用做下拉记忆存储--当事人名称
                            if (this.agentInfo.deputyName) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.partyName',
                            this.agentInfo.deputyName
                        )
                    }

                       // 判断本地有没有数据,用做下拉记忆存储--证件号码
                       if (this.agentInfo.idNum) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.idNum',
                            this.agentInfo.idNum
                        )
                    }
                      // 判断本地有没有数据,用做下拉记忆存储--手机号
                      if (this.agentInfo.phone) {
                        this.$GF.localStorageSelectList(
                            'agentInfo.phone',
                            this.agentInfo.phone
                        )
                    }
                       // 判断本地有没有数据,用做下拉记忆存储--电子邮箱
                       if (this.agentInfo.email) {
                        this.$GF.localStorageSelectList(
                            'agentInfo.email',
                            this.agentInfo.email
                        )
                    }
                           // 判断本地有没有数据,用做下拉记忆存储--单位名称
                           if (this.agentInfo.deputyCompany) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.partyCompany',
                            this.agentInfo.deputyCompany
                        )
                    }
                          // 判断本地有没有数据,用做下拉记忆存储--详细地址
                          if (this.agentInfo.companyAddress) {
                        this.$GF.localStorageSelectList(
                            'partyInfo.idAddress',
                            this.agentInfo.companyAddress
                        )
                    }






                    if (this.partyIdList.length < 1) {
                        return this.$message.info('请选择委托人')
                    }
                    if (this.myMaterialList.length === 0) {
                        if (this.ShowFileList.length === 0) {
                            return this.$message.info('请上传主体资料证明')
                        }
                    }
                    // 校验表格
                    this.submitLoading = true
                    // 上传文件请求 开始
                    let type = await this.uploadFile()
                    if (type) {
                        //     // 保存其他信息
                        this.savaPartyInfoFn()
                        this.submitLoading = false
                    } else {
                        this.submitLoading = false
                        return this.$message.error('文件上传失败')
                    }
                } else {
                    return false
                }
            })
        },

        // 点击请求中-----》保存函数
        async savaPartyInfoFn() {
            this.agentInfo.fileListVos = this.deleteShowFileList
            this.agentInfo.materialFlag = this.type === 1 ? 2 : 4
            this.agentInfo.partyId = this.partyIdList
            this.agentInfo.idNum =
                this.agentInfo.idType === 1
                    ? this.agentInfo.idNum
                    : this.agentInfo.idNum2
            let param = {
                req: {
                    caseId: this.caseObj.caseId,
                    ...this.agentInfo,
                    receiveNo: this.caseObj.receiveNo,
                    digitalId: this.digitalId
                }
            }

            let res = await this.$API.sendRequest('SaveDeputyInfo', param)
            if (!res.success) this.$message.error(res.msg)
            this.$message.success(res.msg)
            setTimeout(() => {
                this.emitClose()
                this.clickResetFields()
            }, 500)
        },

        async uploadFile() {
            if (this.myMaterialList.length === 0) return true
            this.percentageNumType = true
            // 保存其他信息
            this.$message.info('正在上传文件请勿退出')
            let trueNum = 0
            let myThis = this
            for (let i = 0; i < this.myMaterialList.length; i++) {
                let str = this.myMaterialList[i].name
                let pdfType = this.$GF.pdfFlagFn(str)
                const formData = new FormData()
                formData.append('req.abbr', 'WS')
                formData.append('req.typeName', '')
                formData.append('req.profile', '')
                formData.append('req.caseId', this.caseObj.caseId)
                formData.append('file', this.myMaterialList[i].raw)
                formData.append('req.flagPdf', pdfType)
                formData.append('req.oldFileName', this.myMaterialList[i].name)
                formData.append('req.typeId', 2)
                formData.append('req.receiveLetterNo', this.caseObj.receiveNo)
                formData.append('req.partyType', 1)
                formData.append('req.materialFlag', this.type === 1 ? 2 : 4)
                formData.append('req.remarks', null)
                let res = await axios({
                    url: '/api/ocrUse/ocrSaveUpload',
                    method: 'POST',
                    onUploadProgress: function (progressEvent) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )

                        if (percentCompleted < 100) {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = percentCompleted
                        } else {
                            // 可以在这里更新用户界面以显示上传进度
                            myThis.percentageNum = 99
                        }
                    },
                    headers: {
                        c_Auth_token: localStorage.getItem('c_Auth_toke')
                    },
                    data: formData
                })
                this.digitalId.push(res.data.data)
                if (res.data.success) {
                    trueNum++
                    this.trueNum = trueNum
                    if (trueNum === this.myMaterialList.length) {
                        return true
                    } else {
                        myThis.percentageNum = 0
                    }
                } else {
                    return false
                }
            }
        },

        // 申请人列表上传文件中点击查看
        clickViewPreview(scope) {
            let url = window.URL.createObjectURL(scope.row.raw)
            window.open(url)
            URL.revokeObjectURL(url)
        },

        // 点击已添加列表里的预览
        async newclickViewPreview(row) {
            this.materialBoxLoading = true
            let param = {
                req: {
                    ...row
                }
            }
            const res = await this.$API.sendRequest(
                'ocrDownFilePathAndName',
                param
            )
            this.materialBoxLoading = false
            let blob
            if (row.flagPdf === 1) {
                blob = new Blob([res], { type: 'application/pdf' })
                window.open(window.URL.createObjectURL(blob))
            } else {
                // this.dialogPreview = true
                blob = new Blob([res], { type: 'image/jpeg' })
                let newWindow = window.open(window.URL.createObjectURL(blob))
                newWindow.onload = () => {
                    let button = document.createElement('button')
                    this.$GF.cretateRotateBtn(newWindow, button)
                }
            }
        },

        // 选择国家更换证件类型
        countrySelectChange() {
            this.agentInfo.idType = ''
        },

        // 判断对象里面的值是不是都填入了
        checkArrayObjectsValuesEmpty(arr) {
            for (var i = 0; i < arr.length; i++) {
                var obj = arr[i]
                for (var key in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, key)) {
                        if (obj[key] === '') {
                            return true // 如果有一个值为空，返回true
                        }
                    }
                }
            }
            return false // 所有对象的值都不为空，返回false
        },

        // 调用外面关闭弹窗
        emitClose() {
            this.$emit('agentDialogClose')
        },

        // 统一接口调用数据
        async GetDictionaryLtems(req) {
            if (this.$store.state[req]) {
                return this.$store.state[req]
            }

            let param = {
                req
            }
            let res = await this.$API.sendRequest('styleTypeList', param)

            this.$store.commit('setPartyList', {
                key: req,
                list: res.data.materialStyleList
            })
            return res.data.materialStyleList
        },

        // 下载模版
        async downloadDoc(type) {
            let param = {
                req: type
            }
            let res = await this.$API.sendRequest('TemplateDownloadDoc', param)
            let blob = new Blob([res], {
                type: 'application/msword'
            })

            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.download = '授权委托书模板'
            link.click()
            window.URL.revokeObjectURL(link.href)
        },

        // 地址 省
        async clickStyleTypeList(req = 0) {
            let param = {
                req
            }
            let res = await this.$API.sendRequest('GetProvince', param)
            return res.data
        },

        // 省改变
        // 省改变
        async areaNameChange() {
            this.marketList = await this.clickStyleTypeList(this.agentInfo.area)
            this.agentInfo.deputyCity = ''
            this.agentInfo.district = ''
        },
        // 区改变
        async marketChange() {
            this.countyList = await this.clickStyleTypeList(
                this.agentInfo.deputyCity
            )
            this.agentInfo.district = ''
        },

        // 来函人单位---当事人名称
        agentInfoDeputyNameList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.partyName')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.partyName'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---证件号码
        agentInfoIdNumList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.idNum')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.idNum'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---手机号
        agentInfoPhoneList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('agentInfo.phone')) {
                arr = JSON.parse(localStorage.getItem('agentInfo.phone'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---电子邮箱
        agentInfoEmailList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('agentInfo.email')) {
                arr = JSON.parse(localStorage.getItem('agentInfo.email'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---单位名称
        agentInfoDeputyCompanyList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.partyCompany')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.partyCompany'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
        // 来函人单位---详细地址
        agentInfoCompanyAddressList(queryString, callback) {
            let arr = []
            if (localStorage.getItem('partyInfo.idAddress')) {
                arr = JSON.parse(localStorage.getItem('partyInfo.idAddress'))
            }
            // 示例：模拟异步请求获取补全选项
            callback(arr.filter((item) => item.value.includes(queryString)))
        },
    },

    computed: {
        // 选择国家返回指定的证件类型-------------》计算属性
        newGetDictionaryLtemsList() {
            if (this.agentInfo.nationality !== '') {
                if (this.agentInfo.nationality === 1) {
                    //中国
                    let arr = [
                        this.getDictionaryLtemsList[0]
                        // this.getDictionaryLtemsList[1]
                    ]
                    return arr
                } else if (this.agentInfo.nationality === 10) {
                    //台湾
                    let arr = [this.getDictionaryLtemsList[5]]
                    return arr
                } else if (this.agentInfo.nationality === 8) {
                    //香港
                    let arr = [
                        this.getDictionaryLtemsList[2],
                        this.getDictionaryLtemsList[3],
                        this.getDictionaryLtemsList[4]
                    ]
                    return arr
                } else if (this.agentInfo.nationality === 9) {
                    //澳门
                    let arr = [this.getDictionaryLtemsList[4]]
                    return arr
                } else {
                    let arr = [this.getDictionaryLtemsList[6]]
                    return arr
                }
            }
            return {}
        }
    }
}
</script>


<style lang="scss" scoped>
.partyBox {
    padding: 0 20px;

    .partyInfoBox {
        width: 100%;
        font-size: 16px;

        &>div:nth-child(3) {
            :deep(.el-scrollbar) {
                display: none !important;
            }
        }
    }

    .el-col-4 {
        display: flex;
        align-items: center;

        p {
            width: 100%;
            text-align: right;
            padding-right: 55px;

            &:not(:first-child) {
                margin-top: 0;
            }
        }
    }

    .el-col-20 {
        display: flex;
        align-content: center;
    }
}

:deep(.el-dialog__body) {
    padding-top: 0;
}

.el-divider--horizontal {
    margin: 0;
}

.btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.cardTextBox {
    margin-top: 20px;

    span {
        color: #699eff;
    }
}

.selectAddress {
    .el-col {
        margin: 0 10px;

        &:nth-child(1) {
            margin: 0;
        }
    }
}

.partyForm {
    :deep(.el-form-item) {
        width: 100%;
    }
}

.el-checkbox-group {
    display: flex;
    align-items: center;
}

.requireIconBox {
    position: relative;

    span {
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

.progressBox {
    display: flex;
    align-items: center;
    height: 100%;
}

.progressTextColor {
    color: red;
}

:deep(.el-checkbox.el-checkbox--large .el-checkbox__inner) {
    width: 25px;
    height: 25px;

    &::after {
        height: 15px;
        width: 10px;
        left: 5px;
    }
}

.selectBox {
    display: flex;
}
</style>